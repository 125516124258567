<script>
    import { getContext } from 'svelte';
    const settingsContext = getContext('settingsContext');
    const { authUser, data } = settingsContext;
</script>

<div
    class="connection-item dark:bg-white-200 mt-2 flex justify-between rounded-md bg-gray-200 px-3 py-3 dark:bg-opacity-10"
>
    <div class="social-icon flex flex-row">
        <svg
            class="fill-black dark:fill-white"
            width="26"
            height="24"
            viewBox="0 0 26 24"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.834185 0.063015C0.823971 0.0782185 1.65132 1.19819 2.66762 2.54622C3.68393 3.89424 5.84422 6.76259 7.46827 8.91639L10.4202 12.8338L10.2312 13.0466C10.1239 13.1632 7.95344 15.4893 5.41012 18.2157C2.8668 20.9472 0.778008 23.1973 0.7729 23.2227C0.762686 23.248 1.207 23.2683 1.84539 23.2632L2.93319 23.2581L3.29069 22.8679C3.48986 22.6551 5.33862 20.6736 7.40188 18.459C9.46514 16.2444 11.2118 14.3693 11.2833 14.2882L11.4109 14.1412L12.4323 15.4994C12.9941 16.2444 14.5416 18.3019 15.8694 20.0655L18.2902 23.2683H21.9928C25.0723 23.2683 25.6903 23.2581 25.6699 23.2024C25.6546 23.1619 24.7149 21.9051 23.5811 20.3999C22.4473 18.8999 21.1501 17.1768 20.7007 16.5788C20.2462 15.9808 18.9132 14.2021 17.7284 12.6361C16.5435 11.0651 15.5732 9.75764 15.5732 9.73737C15.5732 9.70189 21.8345 2.95671 23.9641 0.701553L24.5719 0.0579472H23.4534L22.3401 0.063015L21.0378 1.46172C20.3228 2.23202 18.571 4.11722 17.1411 5.64262L14.5518 8.41975L11.4109 4.25405L8.26498 0.0832863L4.56235 0.0579472C2.52463 0.0427437 0.844399 0.0478115 0.834185 0.063015ZM9.60813 4.55305C10.8185 6.1646 12.6877 8.64273 13.7602 10.0668C14.8327 11.4857 17.2126 14.648 19.0511 17.0856C20.8897 19.5283 22.4116 21.5503 22.4371 21.5858C22.4677 21.6314 22.1051 21.6466 20.7824 21.6466H19.092L15.7417 17.1971C8.00962 6.94503 4.10782 1.76578 4.06185 1.68977C4.03121 1.64416 4.37849 1.62895 5.71144 1.62895H7.40188L9.60813 4.55305Z"
                fill="currentColor"
            ></path>
        </svg>
        <span class="font-mona-expanded ml-2 hidden dark:text-white md:!flex">X (Twitter)</span>
    </div>
    {#if authUser['twitter_id'] !== null}
        <div class="connect-status flex items-center">
            <span class="h-2 w-2 rounded-full bg-green-500"></span>&nbsp;&nbsp;
            <span class="text-[#000] dark:text-white">connected</span>
        </div>
        <div class="connect-action">
            <a
                href="/auth/twitter/unlink"
                class="rounded-md bg-red-500 px-4 py-2 text-white hover:!no-underline dark:bg-red-900">Unlink</a
            >
        </div>
    {:else}
        <div class="connect-status flex items-center">
            <span class="flex h-2 w-2 rounded-full bg-red-500"></span>&nbsp;&nbsp;
            <span class="">not connected</span>
        </div>
        <div class="connect-action">
            <a
                href="/auth/twitter/connect"
                class="rounded-md bg-bmn-500 px-4 py-2 text-white transition-all duration-150 hover:bg-bmn-600 hover:!no-underline"
                >Connect Now</a
            >
        </div>
    {/if}
</div>
